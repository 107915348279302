import React from 'react'
import paluch from '../../images/ikony/paluch.svg'



export default function ButtonPaluch(props) {
  return <div className='button-goto'>
     <img src={paluch} alt="Przejdź do/Zobacz więcej" />
     <span className="przycisk">{props.text}</span>
  </div>
}
