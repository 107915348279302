import React from 'react'
import ButtonPaluch from '../buttons/button_goto.js'
import Img from "gatsby-image"
import TransitionLink from 'gatsby-plugin-transition-link'


class SliderKarta extends React.Component {
  constructor(props) {
    super(props)
    const czyUslugi = this.props.usluga
  }



  

 

  render()
   {

    function NormalnyImg(props) {
      
      // return <div style={{
      //   backgroundImage: "url(" + props.url + ")",
      //   backgroundPosition: 'center',
      //   backgroundSize: 'cover',
      //   width: '100%',
      //   height: '100%',
      // }}></div>

      return <img className="usluga-fotka" src={props.url} />
    }
    
    function GatsbyImg(props) {
      return <Img fluid={props.url} />;
    }
  
    function Obraz(props) {
      if (props.usluga === true) {
        console.log("NORMALNY");
        return <NormalnyImg url={props.url} />;
      }
      else {
        console.log("GATSBY");
        return <NormalnyImg url={props.url} />;
      }
      
    }


     return(
      

    <div className="karta-ratio">
      <div className="karta-inner">
      <TransitionLink
      state={{ title: 'PROJEKTY' }}
      activeClassName="podstrona-aktywna"
      partiallyActive={true}
      to={this.props.slug}
      exit={{
        length: 1,
        appearAfter: 1,   
        state: { x: -1200},
      }}
      
      entry={{
        length: 1,
        appearAfter: 1,             
        state: {x: 1200, len: 1200},
      }}
    >
         <ButtonPaluch text="ZOBACZ" />
         </TransitionLink>
        <div className="karta-tekst">
          <div>
          <span className="przycisk" style={{marginBottom: '10px'}}>{this.props.projekt}</span>
          <h5>{this.props.header}</h5>
          </div>
          
        </div>
        <div className="karta-okladka">
          <Obraz usluga={this.props.usluga} url={this.props.okladka} />
        </div>
      </div>
    </div>
    
  )
  }
}

export default SliderKarta
