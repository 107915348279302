import React, {useEffect, useRef, useMemo} from 'react'
import SliderKarta from './slider_karta.js'
import { useStaticQuery, graphql } from "gatsby"
import Slick from "react-slick"
import branding from "../../images/podstrony/uslugi/okladka_branding1.jpg"
import design from "../../images/podstrony/uslugi/okladka_design1.jpg"
import media from "../../images/podstrony/uslugi/okladka_media1.jpg"

import "slick-carousel/slick/slick.css"




  export default function Slider(props) {

    const slider = useRef(null);

    // const onWheel = (event) => {

    //   slide(event.deltaX)
    //   console.log(event.deltaX)
    //   console.log(event)
    // };

    
    function slidey(y){
      console.log("dochodze");
      y > 0 ? (
         slider.current.slickNext()
      ) : (
         slider.current.slickPrev()
      )
     }

     const onWheel = (event) => {
      slidey(event.deltaY);
    };

   




    const projekty_jsonn = [
  
      {
       "klient": "Betard",
       "data": "2015-2020",
       "zajawka": "Betard to wiodący producent prefabrykatów betonowych na rynku Polskim. Od 2019 roku wspieramy działania brandingowe marki.",
       "url": "../../images/projekty/betard/okladka@2x.jpg",
       "url2": "../../images/projekty/betard/okladka2@2x.jpg",
       "url3": "../../images/projekty/betard/okladka_mobile.jpg",
       "slug": "/projekty/betard",
       "slider_opis": "WIODĄCY PRODUCENT PREFABRYKACJI BETONOWEJ W POLSCE",
       "zakres": [
          "Badania i koncept",
          "Strategia wizualna",
          "Brand design",
          "Katalogi",
          "Akcydensy",
          "Web design"
         ]
      },
      {
       "klient": "Speedway Partner",
       "data": "2015-2020",
       "slug": "/projekty/speedway",
       "url": "../../images/projekty/speedway/okladka@2x.jpg",
       "url2": "../../images/projekty/speedway/okladka2@2x.jpg",
       "url3": "../../images/projekty/speedway/okladka_mobile.jpg",
       "slider_opis": "WIODĄCY PRODUCENT PREFABRYKACJI BETONOWEJ W POLSCE",
       "zajawka": "Pojęcie archetypu zostało spopularyzowane w pierwszej połowie XX w. przez Carla Gustava Junga, dla którego Inspirację stanowiły pisma starożytnych, m.in. św. Augustyna i Dionizego.",
       "zakres": ["Strategia", "Media", "Print"]
    },
    {
       "klient": "Maciej Janowski",
       "data": "2015-2020",
       "slug": "/projekty/magic",
       "url": "../../images/projekty/magic/okladka@2x.jpg",
       "url2": "../../images/projekty/magic/okladka2@2x.jpg",
       "url3": "../../images/projekty/magic/okladka_mobile.jpg",
       "slider_opis": "OBSŁUGA JEDNEGO Z NAJBARDZIEJ UTYTUŁOWANYCH ŻUŻLOWCÓW ŚWIATA",
       "zajawka": "Maciej Janowski to jeden z najbardziej utytułowanych żużlowców na świecie. 2016 roku zajmujemy się wizerunkiem popularnego Magica współpracując w obszarach brandingu, projektowania i mediów.",
       "zakres": ["Badania i koncept", "Strategia wizualna", "Brand design", "Katalogi", "Akcydensy","Web design"]
    },
    {
       "klient": "Volley Wrocław",
       "data": "2015-2020",
       "slug": "/projekty/volley",
       "url": "../../images/projekty/volley/okladka@2x.jpg",
       "url2": "../../images/projekty/volley/okladka2@2x.jpg",
       "url3": "../../images/projekty/volley/okladka_mobile.jpg",
       "slider_opis": "PEŁNA OBSŁUGA KLUBU SIATKÓWKI KOBIET",
       "zajawka": "Od 2015 roku odpowiadamy za warstwę wizualną  klubu, zarówno w obszarze systemu identyfikacji wizualnej oraz ewolucji cosezonowych platform komunikacji.",
       "zakres": ["Badania i koncept", "Strategia wizualna", "Brand design", "Katalogi", "Akcydensy", "Web design"]
    },
    
    {
       "klient": "Lato na podwórku",
       "data": "2020-2021",
       "slug": "/projekty/lato",
       "url": "../../images/projekty/lato/okladka@2x.jpg",
       "url2": "../../images/projekty/lato/okladka2@2x.jpg",
       "url3": "../../images/projekty/lato/okladka_mobile.jpg",
       "slider_opis": "WSPIERAMY ROZWÓJ KOMPETENCJI SPOŁECZNYCH U DZIECI",
       "zajawka": "„Lato na podwórku” to projekt wspierający rozwój kompetencji społecznych wśród dzieci. Naszym zadaniem było stworzenie identyfikacji wizualnej, kreacji reklamowych oraz materiałów dydaktycznych.",
       "zakres": ["Identyfikacja wizualna", "Print", "Digital design"]
    },
    {
       "klient": "K-HOLE TRAX",
       "data": "2016-2021",
       "slug": "/projekty/khole",
       "url": "../../images/projekty/khole/okladka@2x.jpg",
       "url2": "../../images/projekty/khole/okladka2@2x.jpg",
       "url3": "../../images/projekty/khole/okladka_mobile.jpg",
       "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
       "zajawka": "K-Hole to wrocławski label funkcjonujący głównie w analogowym środowisku. Miało być miejsko, brudno i lo-fi. Oprócz identyfikacji marki, stworzyliśmy szereg okładek i projekty do social mediów.",
       "zakres": ["Identyfikacja wizualna", "Print", "Digital design"]
    },
    {
       "klient": "Beefit",
       "data": "2020-2021",
       "slug": "/projekty/beefit",
       "url": "../../images/projekty/beefit/okladka@2x.jpg",
       "url2": "../../images/projekty/beefit/okladka2@2x.jpg",
       "url3": "../../images/projekty/beefit/okladka_mobile.jpg",
       "slider_opis": "NOWOCZESNA MARKA SUPLEMENTACJI DLA SPORTOWCÓW",
       "zajawka": "Rite to nowoczesna kancelaria prawna, założona przez młody i dynamiczny zespół prawników z Wrocławia.",
       "zakres": ["Design", "Strategia"]
    },
   {
     "klient": "Rite",
     "data": "2020-2021",
     "slug": "/projekty/rite",
     "url": "../../images/projekty/rite/okladka@2x.jpg",
     "url2": "../../images/projekty/rite/okladka2@2x.jpg",
     "url3": "../../images/projekty/rite/okladka_mobile.jpg",
     "slider_opis": "BRANDING NOWOCZESNEJ KANCELARII PRAWNEJ",
     "zajawka": "Rite to nowoczesna kancelaria prawna, założona przez młody i dynamiczny zespół prawników z Wrocławia.",
     "zakres": ["Badania i koncept", "Strategia wizualna", "Brand design", "Katalogi", "Akcydensy", "Web design"]
   },
   {
    "klient": "KO GYM",
    "data": "2020",
    "slug": "/projekty/kogym",
    "url": "../../images/projekty/kogym/okladka@2x.jpg",
    "url2": "../../images/projekty/kogym/okladka2@2x.jpg",
    "url3": "../../images/projekty/kogym/okladka_karta.jpg",
    "slider_opis": "SZKOŁA WALKI MISTRZA NAD MISTRZAMI, MARIUSZA CIEŚLIŃSKIEGO",
    "zajawka": "Niewielka realizacja dla szkoły sportów walki w Świebodzicach, prowadzonej przez czempiona nad czempionami, Mariusza Cieślińskiego, legendy kick-boxingu i trenera Betard Sparty Wrocław. ",
    "zakres": ["Identyfikacja wizualna", "Print", "Digital design", "Fotografia"]
   },
   
   
   
   
   
   
   {
    "klient": "Red Bull",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "W ramach współpracy z marką Red Bull realizowaliśmy m.in. aktywacje social mediowe a także produkowaliśmy film dokumentalny o Macieju Janowskim, którego premiera już niebawem w RedBull.tv! Więcej informacji o projektach wkrótce.",
    "zakres": ["Social media", "Ilustracja", "Film", "Media direction"]
   },
   
   {
    "klient": "Mobile Vikings",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "MV to jeden z największych wirtualnych operatorów telefonii komórkowej w kraju. W ciągu trzech lat współpracy stworzyliśmy setki materiałów do social mediów marki, druki, realizowaliśmy sesje zdjęciowe i współtworzyliśmy koncepcje kampanii reklamowych. Więcej niebawem, zobacz projekt w naszych social mediach @odrastudio.",
    "zakres": ["Strategia", "Media", "Print"]
   },
   
   {
    "klient": "Solar",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "We współpracy z marką Solar i agencją Warsaw Creatives, stworzyliśmy zestaw animacji inspirowanych najnowszą kolekcją odzieży Solar, które towarzyszyły modelkom na wybiegu podczas pokazu Doskonałość Mody wg magazynu Twój Styl. Więcej niebawem, zobacz projekt w naszych social mediach @odrastudio.",
    "zakres": ["Strategia", "Media", "Print"]
   },
   
   {
    "klient": "Levi's",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "Projekty animacji dla marki Levi’s, stworzone na warszawską edycję Fashion Week. Realizowane na zlecenie Warsaw Creatives. Więcej niebawem - tymczasem zobacz projekt w naszych social mediach @odrastudio.",
    "zakres": ["Strategia", "Media", "Print"]
   },
   
   {
    "klient": "Karpicko",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "Karpicko to jedna z największych cukierni w Polsce. Dla marki realizowaliśmy działania z obszaru redesignu, web designu (www.cukierniakarpicko.pl) oraz druku. Więcej w naszych mediach społecznościowych (@odrastudio) - case study niebawem.",
    "zakres": ["Strategia", "Media", "Print"]
   },
   
   {
    "klient": "Kombucha Karate & Mate Moc",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "Case study wkrótce - zobacz więcej na naszych social mediach",
    "zakres": ["Strategia", "Media", "Print"]
   },
   
   {
    "klient": "Hotel Brant",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "Case study wkrótce - zobacz więcej na naszych social mediach",
    "zakres": ["Strategia", "Media", "Print"]
   },
   
   {
    "klient": "Gleb Czugunow",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "Gleb Chugunov to kolejny wybitny żużlowiec, którego mieliśmy przyjemność obsługiwać w Odrze. Dla zawodnika realizowaliśmy projekt brandingu busa oraz projekt malowania kasku, w którym Gleb ściga się w sezonie 2021. Case study niebawem - odwiedź nasze social media by zobaczyć więcej.",
    "zakres": ["Strategia", "Media", "Print"]
   },
   
   {
    "klient": "Pszczółek",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "Case study wkrótce - zobacz więcej na naszych social mediach",
    "zakres": ["Strategia", "Media", "Print"]
   },
   
   {
    "klient": "Tony",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "Case study wkrótce - zobacz więcej na naszych social mediach",
    "zakres": ["Strategia", "Media", "Print"]
   },
   
   {
    "klient": "Tulident",
    "data": "2015-2020",
    "slug": "/projekty/",
    "url": "../../images/projekty/soon@2x.jpg",
    "url2": "../../images/projekty/soon@2x.jpg",
    "url3": "../../images/projekty/soon@2x.jpg",
    "slider_opis": "WROCŁAWSKI LABEL FUNKCJONUJĄCY W ANALOGOWYM ŚWIECIE",
    "zajawka": "Case study wkrótce - zobacz więcej na naszych social mediach",
    "zakres": ["Strategia", "Media", "Print"]
   }
   
   
   
   ]
   

    if(props.uslugi) {

    let settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 300,
      slidesToShow: 2.2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 1.6,
            slidesToScroll: 1,

    
          }
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,

    
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,

    
          }
        },

    ],
    }

    const projekty_json = [
      {
        id: "1",
        firma: "BRANDING",
        opis: "PROJEKTUJEMY WYJĄTKOWE MARKI",
        logo: branding,
        url: "/uslugi/branding"
      },
      {
        id: "2",
        firma: "DESIGN",
        opis: "PROJEKTUJEMY WYJĄTKOWE DOŚWIADCZENIA",
        logo: design,
        url: "/uslugi/design"
      },
      {
        id: "3",
        firma: "MEDIA",
        opis: "PROJEKTUJEMY WYJĄTKOWE INTERAKCJE",
        logo: media,
        url: "/uslugi/media"
      },
    ]


    

    return(
      <>
      <h6 className="tytul-sekcji-maly">Wybrane usługi</h6>
      <Slick   onWheel={onWheel} ref={slider} className="polecane polecane2" {...settings}>

         {projekty_json.map((projekt) => {
                return <SliderKarta
                  usluga={true}
                  key={projekt.id}
                  header={projekt.opis}
                  projekt={projekt.firma}
                  okladka={projekt.logo}
                  slug={projekt.url} />
              })}
      </Slick>
      </>

    )

    }

    else {
      let settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 300,
        slidesToShow: 1.6,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 1.6,
              slidesToScroll: 1,
  
      
            }
          },
          {
              breakpoint: 1300,
              settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
    
        
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
    
        
              }
            },
  
      ],
      }
  
      
  
  
  
      return(
        <>
        <h6 className="tytul-sekcji-maly">Wybrane realizacje</h6>
        <Slick 
        ref={slider} 
        onWheel={onWheel}
        className="polecane polecane3" 
        {...settings}
        >
  
           {projekty_jsonn.map((projekt) => {
             if(projekt.url3.includes("soon")) {
               
             }
             else {

              return <SliderKarta
              key={projekt.id}

              projekt={projekt.klient}
              url={projekt.url} 
              header={projekt.slider_opis}
              slug={projekt.slug}
              okladka={projekt.url3} />
             }
                  
                })}
        </Slick>
        </>
  
      )
    }




}





